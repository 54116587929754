import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  colors,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useGetCreditStatusQuery,
  useSubmitCreditRequestMutation,
} from "../../services/ProfileServices";
import {
  CreditCustomerStatuses,
  CreditStatusModel,
} from "../../models/CreditStatusModel";
import { showToastMessage, snakeToCamel } from "../../common/Helper";
import CreditRequestForm from "../Auth/CreditRequestForm";
import { SupplierDetails } from "../Auth/suppliers/SupplierDetails";
import { useDispatch, useSelector } from "react-redux";
import { getcompanyData } from "../../reduxenv/Slices/CompanyDataSlice";
import { getSuppliers } from "../../reduxenv/Slices/SupplierSlice";
import { CreditReSubmitModel } from "../../models/creditReSubmitModel";
import { AppColors } from "../../common/AppColors";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import useConfirm from "../../hooks/useConfirm";
import useMessage from "../../hooks/useMessage";

export const CreditStatus: React.FC = () => {
  const dispatch = useDispatch();
  const { data, refetch: refetchCreditStatus } = useGetCreditStatusQuery();
  const [submitCreditRequest] = useSubmitCreditRequestMutation();
  const [creditStatus, setCrediStatus] = useState<CreditStatusModel>();
  const [showCreditRequestForm, setShowCreditRequestForm] = useState(false);
  const [showSuppliers, setShowSuppliers] = useState(false);
  const company = useSelector(getcompanyData);
  const suppliers = useSelector(getSuppliers);
  const [Alert, confirmResubmit] = useConfirm(
    "Resubmit Credit Request",
    "Credit request is rejected, Do you want to re submit?",
    true,
    "Rejection Note: " + creditStatus?.note!
  );
  const [StatusMessage, showStatusMessage] = useMessage();

  useEffect(() => {
    if (data && data.status === "success") {
      let tempStatus = snakeToCamel(data.response) as CreditStatusModel;
      if (!tempStatus?.status) {
        tempStatus.status = CreditCustomerStatuses.none;
      }
      setCrediStatus(tempStatus);
    }
  }, [data]);

  async function handleClick() {
    if (creditStatus?.status === CreditCustomerStatuses.none) {
      setShowCreditRequestForm(true);
    } else if (creditStatus?.status === CreditCustomerStatuses.rejected) {
      let resubmitConfirmed = await confirmResubmit();
      if (resubmitConfirmed) {
        setShowCreditRequestForm(true);
      }
    } else if (creditStatus?.status === CreditCustomerStatuses.suspended) {
      showStatusMessage(
        "Credit request " + creditStatus?.status.toLowerCase(),
        creditStatus?.note
      );
    }
  }

  function closeAll() {
    setShowCreditRequestForm(false);
    setShowSuppliers(false);
  }

  function showSupplierForm(_index: number) {
    setShowSuppliers(true);
    setShowCreditRequestForm(false);
  }

  async function submit(_index: number) {
    if (company && suppliers) {
      let creditRequest: CreditReSubmitModel = {
        expectedMonthlyPurchase: company.expectedMonthlyPurchase!,
        creditLimit: company.creditLimit!,
        yearEndDate: company.yearEndDate!,
        isAudited: company.isAudited!,
        profitLossStatements: company.profitLossStatements!,
        balanceSheets: company.balanceSheets!,
        suppliers: suppliers,
        type: company.type!,
        isPublicCompany: company.isPublicCompany!
      };
      dispatch(setIsLoading(true));
      submitCreditRequest({ creditRequest: creditRequest })
        .then((res: any) => {
          let response = res.data as ResponseModel;
          showToastMessage(response.status, response.message);
          dispatch(setIsLoading(false));
          closeAll();
          refetchCreditStatus();
        })
        .catch(() => {
          dispatch(setIsLoading(false));
          closeAll();
        });
    }
  }

  return creditStatus?.status !== "" ? (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        fullWidth
        sx={{
          color:
            creditStatus?.status === CreditCustomerStatuses.rejected
              ? colors.red[400]
              : AppColors.White,
          position: "absolute",
          bottom: "10px",
          width: { xs: "95%", lg: "20%" },
          ml: { xs: 2, lg: 20 },
          fontWeight: "bold",
          borderRadius: 5,
          p: 0.8,
        }}
      >
        {creditStatus?.status === CreditCustomerStatuses.none ? (
          <Typography
            variant="subtitle2"
            color={AppColors.White}
            textTransform={"capitalize"}
            mr={0.8}
          >
            Signup credit customer
          </Typography>
        ) : (
          <>
            <Typography
              variant="subtitle2"
              color={AppColors.White}
              textTransform={"capitalize"}
              mr={0.8}
            >
              Credit Status |
            </Typography>
            <>{creditStatus?.status ?? "UNKNOWN"}</>
          </>
        )}
      </Button>
      <Dialog
        fullWidth
        open={showCreditRequestForm || showSuppliers}
        onClose={closeAll}
        maxWidth={"md"}
      >
        <DialogContent>
          {showCreditRequestForm ? (
            <CreditRequestForm
              handleGoToNextStep={showSupplierForm}
              fromProfilePage
            />
          ) : (
            <SupplierDetails handleGoToNextStep={submit} fromProfilePage />
          )}
        </DialogContent>
      </Dialog>
      <Alert />
      <StatusMessage />
    </>
  ) : null;
};
