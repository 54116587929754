import { colors } from "@mui/material";

export const AppColors = {
    Blue: "#3E485D",
    LightGray: "#F5F5F5",
    MainColor: colors.blue[700],
    LightColor: colors.blue[50],
    White: colors.common.white,
    Black: colors.common.black,
    DarkGray: "#A8A8A8"
}