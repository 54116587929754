import {
  ListRounded,
  LocalShipping,
  LocationOnRounded,
  Numbers,
  PhoneRounded,
  PriceChangeRounded,
} from "@mui/icons-material";
import {
  Button,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { OrderModel, paymentStatuses } from "../../models/OrderModel";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { RefundHistoryList } from "./RefundHistoryList";

interface Props {
  styles: any;
  order: OrderModel;
}

export const OrderDetails: React.FC<Props> = (props) => {
  let styles = props.styles;
  let order = props.order;
  const { formatCurrency } = useFormatCurrency();

  return (
    <DialogContent>
      <DialogTitle>
        Order Details <span style={styles.orderNo}>#{order.id}</span>
      </DialogTitle>
      <DialogContent>
        <List>
          {order.orderDeliveryDetails &&
            order.orderDeliveryDetails.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <LocalShipping />
                </ListItemIcon>
                <ListItemText
                  primary={"Delivery Details"}
                  secondary={
                    <>
                      {order.orderDeliveryDetails[0].contactName}
                      <br />
                      {order.orderDeliveryDetails[0].contactPhone}
                      <br />
                      {order.orderDeliveryDetails[0].deliveryAddress}
                    </>
                  }
                />
              </ListItem>
            )}
          <ListItem>
            <ListItemIcon>
              <LocationOnRounded />
            </ListItemIcon>
            <ListItemText
              primary={"Billing Address"}
              secondary={order.billingAddress}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneRounded />
            </ListItemIcon>
            <ListItemText primary={"Phone"} secondary={order.contactPhone} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PriceChangeRounded />
            </ListItemIcon>
            <ListItemText
              primary={"Total"}
              secondary={formatCurrency(order.grandtotal)}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Numbers />
            </ListItemIcon>
            <ListItemText
              primary={"PO Number"}
              secondary={order.poNumber ?? "N/A"}
            />
          </ListItem>
        </List>
        {order.paymentStatus === paymentStatuses.refunded && (
          <RefundHistoryList orderId={order.id} />
        )}
        <Button
          type="button"
          startIcon={<ListRounded />}
          fullWidth
          variant="contained"
          onClick={() => {
            window.open(order.invoiceLink, "_blank");
          }}
        >
          View Invoice
        </Button>
      </DialogContent>
    </DialogContent>
  );
};
