import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const cart: StyleModel = {
    mobile: {
        navButton: { color: AppColors.White, display: "inline-block" },
        cartItem: {
            bgcolor: AppColors.LightColor,
            borderRadius: 1.8,
            boxShadow: "rgb(225 225 225 / 59%) -2px 1px 20px",
            display: "flex",
            p: 1,
            mb: 1
        },
        image: {
            width: "18vw",
            height: "18vw",
            borderRadius: 1.5
        },
        productName: {            
            mb: 0,
            lineHeight: "20px"
        },
        price: {
            fontSize: "large",
            fontWeight: "bold"
        },
        wrapper: {
            display: "flex",
            alignItems: "center"
        },
        content: {
            marginLeft: "4.83vw",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        contentInner: {
            width: "100%"
        },
        quantity: {
            marginLeft: "auto"
        },
        deleteButton: {
            marginLeft: "auto",
            fontSize: "0.83vw",
            color: AppColors.MainColor
        },
        subtitle: {
            mt: 0
        },
        totalBox: {
            width: { xs: "100vw", lg: "15vw" },
            marginLeft: "auto",
            borderRadius: "10px",
            border: "1px solid " + AppColors.MainColor,
            mt: 2
        },
        totalPrice: {
            mb: 0,
            p: 1,
            borderRadius: "10px 10px 0 0",
            bgcolor: AppColors.LightColor,
            color: AppColors.Blue,
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "4.83vw"
        },
        gst: {
            fontSize: "2.83vw",
            fontWeight: "normal",
            color: AppColors.Black,
            display: "block"
        },
        checkoutButton: {
            p: 1,
            fontWeight: "bold",
            borderRadius: "0 0 10px 10px",
            "&: focus": {
                bgcolor: AppColors.LightColor,
                boxShadow: "none",
                color: AppColors.MainColor,
            },
        },
        cartEmpty: {
            borderRadius: "10px", 
            border: "1px solid " + AppColors.MainColor,
            height: "20vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: AppColors.MainColor
        }
    },
    desktop: {
        image: {
            width: "7.29vw",
            height: "7.29vw"
        },
        wrapper: {
            height: "2vw"
        },
        content: {
            marginLeft: "0.83vw"
        },
        totalPrice: {
            fontSize: "1.41vw"
        },
        gst: {
            fontSize: "1vw"
        },
        cartEmpty: {
            height: "20vh"
        }
    },
};
