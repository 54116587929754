import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const footer: StyleModel = {
    mobile: {
        container: {
            backgroundColor: AppColors.Blue,
            padding: "4.83vw",
        },
        logo: {
            width: "20vw"
        },
        description: {
            color: AppColors.DarkGray
        },
        caption: {
            color: AppColors.White,
        },
        link: {
            color: AppColors.DarkGray,
            display: "block",
            textDecoration: "none"
        },
        contactUs: {
            color: AppColors.White,
            borderColor: AppColors.White,  
            mt: 2,
            pl: 4,
            pr: 4,
            borderRadius: 2,
            boxShadow: "none",
            "&: hover": {
                borderColor: AppColors.Blue
            }
        },
        wrapper: {
            marginLeft: "1.42vw"
        },
        newsletterField: {
            backgroundColor: AppColors.White,
            mt: 1
        },
        subscribeButton: {
            mt:2
        },
        mobileAppImg: {
            width: "60%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    desktop: {
        container: {
            padding: "7.29vw 7.29vw 2.08vw 7.29vw"
        },
        logo: {
            width: "10vw"
        },
        wrapper: {
            marginLeft: "0.78vw"
        },
        mobileAppImg: {
            width: "100%",            
        }
    }
}