import * as yup from "yup";

export const CompanyFormSchema = yup.object().shape({
  name: yup.string().required().label("Company Name"),
  phone: yup.string().required().label("Phone"),
  email: yup.string().email().required().label("Email Address"),
  buildingNumber: yup.string().required().label("This"),
  streetName: yup.string().required().label("This"),
  city: yup.string().required().label("City"),
  state: yup.string().required().label("State"),

  postCode: yup.string().required().label("This"),
  countryId: yup.number().required(),

  // Billing & Invoice
  billingEmail: yup.string().email().required().label("Invoice Email Address"),
  billingBuildingNumber: yup.string().required().label("Building Number"),
  billingStreetName: yup.string().required().label("Billing Street Name"),
  billingPostCode: yup.string().required().label("Post Code"),
  billingState: yup.string().required().label("State"),
  billingCity: yup.string().required().label("City"),

  // Account Payable
  apName: yup.string().required().label("Name"),
  apPhone: yup.string().required().label("Phone"),
  appEmail: yup.string().email().label("Email"),
});
