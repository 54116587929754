import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { OrderProductModel } from "../../models/OrderProductModel";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { deliveryStatuses, productStatusesMap } from "../../models/OrderModel";
import { KeyRounded, LocationOnRounded } from "@mui/icons-material";

interface Props {
  styles: any;
  products: OrderProductModel[];
  clickViewDigitalKeys(productId: number): void;
}

export const OrdersProductList: React.FC<Props> = (props) => {
  let styles = props.styles;
  const { formatCurrency } = useFormatCurrency();

  return (
    <TableContainer>
      <Table sx={{ width: { xs: "max-content", md: "100%" } }}>
        <TableBody>
          {props.products.slice(0, 10).map((product, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component={"th"} scope="row" style={{ width: "38%" }}>
                <Typography>
                  {product.name}{" "}
                  {product.attributes &&
                    product.attributes.length > 0 &&
                    product.attributes[0] !== "" &&
                    "(" + product.attributes.join(",") + ")"}
                </Typography>
                <Typography sx={styles.price}>
                  {formatCurrency(product.price)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={styles.quantity}>
                  {"Qty: " + product.quantity}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{
                    ...styles.deliveryStatus,
                    color: productStatusesMap.get(product.status)?.color,
                  }}
                >
                  {productStatusesMap.get(product.status)?.title}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: "25%" }}>
                {!product.isDigitalGood ? (
                  <IconButton
                    sx={styles.trackOrder}
                    onClick={() => {
                      window.open("/track/" + product.trackingId, "_blank");
                    }}
                  >
                    <LocationOnRounded />
                  </IconButton>
                ) : (
                  <>
                    {product.status === deliveryStatuses.delivered && (
                      <IconButton
                        sx={styles.trackOrder}
                        onClick={() => {
                          props.clickViewDigitalKeys(product.productId);
                        }}
                      >
                        <KeyRounded />
                      </IconButton>
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
