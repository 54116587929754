import { AppColors } from "../common/AppColors";
import { Images } from "../common/Images";
import { StyleModel } from "../models/StyleModel";

export const header: StyleModel = {
    mobile: {
        container: {
            padding: "3vw 4.83vw",
            position: "sticky",
            top: "0",
            zIndex: "99",
            backgroundColor: AppColors.White,
            backgroundImage: `url("${Images.windowsWallpaper}")`,
            backgroundSize: "100%",
            backgroundPosition: "100% 18%",
            backgroundRepeat: "no-repeat"
        },
        logo: {
            width: "28vw",
            p: 1,
        },
        navbar: {
            display: {
                xs: "none",
                md: "block",
            },
            ml: "auto",
        },
        navLink: {
            color: AppColors.LightGray,
            mr: 1,
            textDecoration: "none"
        },
        navButton: {
            pr: 2, 
            pl: 2,            
            display: "inline-flex",
            "&:hover": {
                color: AppColors.MainColor
            },
        }
    },
    desktop: {
        container: {
            padding: "1vw 7.29vw",
            display: "flex",
            alignItems: "center"
        },
        logo: {
            width: "12.5vw",
        },
    },
};
