import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { StyleContext } from "../../context/StyleContextProvider";
import { FooterTexts } from "../../common/Helper";
import { Link } from "react-router-dom";
import { AppColors } from "../../common/AppColors";
import { PhoneRounded } from "@mui/icons-material";

const Footer: React.FC = () => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState<any>(
    styleContext.getComponentStyle("footer")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("footer"));
  }, [isMobile]);

  return (
    <div style={styles.container}>
      <Grid container spacing={3}>
        <Grid lg={3} item>
          <img style={styles.logo} src="/softvire-white.svg" alt="" />
          <div style={styles.wrapper}>
            <Typography sx={styles.description} variant="body1">
              {FooterTexts.companyDescription}
            </Typography>
          </div>
        </Grid>
        <Grid lg={3} item>
          <Typography sx={styles.caption} variant="button">
            Information
          </Typography>
          <div style={styles.wrapper}>
            <Link style={styles.link} to={"/policy/privacy"}>
              Privacy Policy
            </Link>
            <Link style={styles.link} to={"/policy/return-refund"}>
              Return & Refund Policy
            </Link>
          </div>
        </Grid>
        <Grid lg={3} item>
          <div style={styles.wrapper}>
            <Link to={"/contact-us"}>
              <Button
                variant="outlined"
                disableElevation
                sx={styles.contactUs}
                endIcon={<PhoneRounded />}
              >
                Contact Us
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid lg={3} item>
          <Typography sx={styles.caption} variant="button">
            Softvire reseller mobile app
          </Typography>
          <div style={styles.wrapper}>
            <a href="https://play.google.com/store/apps/details?id=softvire.com.softvire_reseller_portal">
              <img
                style={styles.mobileAppImg}
                src="/get-it-on-google-play.png"
                alt="Get it on GooglePlay"
              />
            </a>
          </div>
          {/* <div style={styles.wrapper}>
            <a href="https://apps.apple.com/us/app/softvire-resellers/id6470967084">
              <img
                style={styles.mobileAppImg}
                src="/available-on-appstore.png"
                alt="Available on the App Store"
              />
            </a>
          </div> */}
        </Grid>
      </Grid>
      <Typography textAlign={"center"} color={AppColors.White}>
        {FooterTexts.copyright}
      </Typography>
    </div>
  );
};

export default Footer;
