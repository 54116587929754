import { Box, Grid } from '@mui/material'
import React from 'react'
import { Images } from '../../common/Images'
import { AppColors } from '../../common/AppColors'

interface Props {
    content?: React.ReactNode
}

export const Wallpaper: React.FC<Props> = (props) => {
    return (
        <Grid item lg={12} xs={12}>
            <Box sx={{
                width: "100%",
                backgroundColor: AppColors.LightColor,
                backgroundImage: `url("${Images.windowsWallpaper}")`,
                height: { lg: "10vw", xs: "20vw" },
                WebkitBackgroundSize: "100%",
                backgroundSize: "100% 600%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: {xs: "100% 42%", lg: "100% 36%"},
                position: "relative"
            }}>
                {props.content && props.content}
            </Box>
        </Grid>

    )
}
