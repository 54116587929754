import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";
import { EditProfileFormModel } from "../models/EditProfileFormModel";
import { OtherResellerModel } from "../models/OtherResellerModel";
import { CreditReSubmitModel } from "../models/creditReSubmitModel";

export const profileAPI = createApi({
  reducerPath: "profileAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCreditStatus: builder.query<ResponseModel, void>({
      query: () => {
        return "/company/credit-status";
      },
      keepUnusedDataFor: 0.00001,
    }),
    getAllStates: builder.query<ResponseModel, { countryId: number }>({
      query: (params) => `/states/all/${params.countryId}`,
    }),
    putProfile: builder.mutation({
      query: (editProfileFormModel: EditProfileFormModel) => ({
        url: "/reseller/update",
        method: "PUT",
        body: editProfileFormModel,
      }),
    }),
    getAllResellers: builder.query<ResponseModel, void>({
      query: () => "/reseller/all",
      keepUnusedDataFor: 0.00001,
    }),
    postOtherReseller: builder.mutation({
      query: (otherReseller: OtherResellerModel) => ({
        url: "/reseller/signup",
        method: "POST",
        body: otherReseller,
      }),
    }),
    deleteOtherReseller: builder.mutation({
      query: (resellerId: number) => ({
        url: `/reseller/delete/${resellerId}`,
        method: "DELETE",
        body: {},
      }),
    }),
    submitCreditRequest: builder.mutation<
      ResponseModel,
      { creditRequest: CreditReSubmitModel }
    >({
      query: (params) => ({
        url: "/company/submit-credit-request",
        method: "POST",
        body: params.creditRequest,
      }),
    }),
    uploadProfilePicture: builder.mutation<ResponseModel, any>({
      query: (formData) => ({
        url: "/reseller/avatar/upload",
        method: "POST",
        body: formData
      })
    })
  }),
});

export const {
  useGetAllStatesQuery,
  usePutProfileMutation,
  usePostOtherResellerMutation,
  useGetAllResellersQuery,
  useDeleteOtherResellerMutation,
  useGetCreditStatusQuery,
  useSubmitCreditRequestMutation,
  useUploadProfilePictureMutation
} = profileAPI;
