import React from "react";
import { OtherResellerModel } from "../../models/OtherResellerModel";
import { PersonRounded, Remove } from "@mui/icons-material";
import {
  IconButton,
  Avatar,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { red } from "@mui/material/colors";

interface Props {
  index: number;
  reseller: OtherResellerModel;
  removeReseller(resellerId: number, index: number): void;
}

export const OtherResellerCard: React.FC<Props> = (props) => {
  let reseller = props.reseller;
  function handleClickRemove(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    props.removeReseller(reseller.id!, props.index);
  }
  return (
    <ListItem>
      <ListItemIcon>
        <PersonRounded />
      </ListItemIcon>
      <ListItemText
        primary={reseller.firstName + " " + reseller.lastName}
        secondary={reseller.email}
      />
      <ListItemIcon>
        <IconButton onClick={handleClickRemove}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              bgcolor: red[500],
            }}
          >
            <Remove />
          </Avatar>
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};
