import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Wallpaper } from "../components/shared/Wallpaper";
import { AppColors } from "../common/AppColors";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Contactdetails } from "../common/ContactDetails";
import { Link } from "react-router-dom";
import { scrollToTop } from "../common/Helper";

export const ContactUsPage: React.FC = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Header />
      <Grid container spacing={2} justifyContent={"center"}>
        <Wallpaper
          content={
            <Typography
              variant="h4"
              color={AppColors.White}
              position={"absolute"}
              bottom={"10px"}
              ml={{ xs: "4.83vw", lg: "7.29vw" }}
              textAlign={"center"}
            >
              Contact Us
            </Typography>
          }
        />
      </Grid>
      <Container maxWidth={"lg"}>
        <Grid container mt={4} mb={6} spacing={2} justifyContent={"center"}>
          {Contactdetails.map((details, index) => (
            <Grid item xs={12} lg={3} key={index} textAlign={"center"}>
              <Typography variant="h6" m={2} fontWeight={"bold"}>
                {details.country}
              </Typography>

              <Typography
                variant="body1"
                width={{ xs: "50%", lg: "100%" }}
                m={"auto"}
              >
                <Link style={{ color: AppColors.MainColor }} to={details.locationUrl} target="_blank">
                  {details.address}
                </Link>
              </Typography>

              <Typography mt={1}>{details.email}</Typography>
              <Typography mt={1}>{details.phone}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
