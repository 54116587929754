import { TypeOptions, toast } from "react-toastify";
import { ProductModel } from "../models/ProductModel";
import { CategoryModel } from "../models/CategoryModel";

export const apiBaseURL: string =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV!
    : process.env.REACT_APP_API_URL_PROD!;

export const appTitle = "SOFTVIRE Reseller";
export const companyEmail = "info@microsoft.softvire.com.au";

export const AuthTexts = {
  noAccount: "Don't have an account? ",
  resellerForm: "Reseller Registration Form",
  selectYourCountry: "Select Your Country",
  forgotPassword: "Forgot password? ",
};

export const FooterTexts = {
  companyDescription:
    "SOFTVIRE is a leading IT distribution company. The company yields a prime destination for various kinds of hardware and software including antivirus, Internet security and much more.",
  copyright: `Copyright © ${new Date().getFullYear()} Softvire. All rights reserved.`,
};

export const localStorageKeys = {
  profile: "profile",
  token: "token",
  isLoggedIn: "isLoggedIn",
  // registration steps -----------------
  country: "country",
  reseller: "reseller",
  company: "company",
  directors: "directors",
  suppliers: "suppliers",
  credits: "credits",
  terms: "terms",
  // -----------------------------------
  cart: "cart",
};

export const showToastMessage = (variant: TypeOptions, message: String) => {
  toast(message, {
    type: variant,
    hideProgressBar: true,
    theme: "colored",
  });
};

export const getMonthAndDay = (dateString: string) => {
  let date = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  return monthName + "-" + year;
};

export function snakeToCamel(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item));
  } else if (typeof obj === "object" && obj !== null) {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
          letter.toUpperCase()
        );
        newObj[camelKey] = snakeToCamel(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj;
  }
}

// Make sure to test if the json property == my_8_variable;

export const getFormatedDate = (date: string) => {
  if (date) {
    let formatedDate = new Date(date).toLocaleTimeString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return formatedDate.replace("at", " | ");
  }
};

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function copyToClipboard(text: string) {
  if (text) {
    navigator.clipboard.writeText(text);
    showToastMessage("success", `"${text}" is copied to clipboard`);
  }
}

export function getKeyByValue(object: Record<any, any>, value: any): any {
  var result = null;
  Object.keys(object).forEach((key) => {
    if (object[key] === value) {
      result = key;
      return;
    }
  });
  return result;
}
