import { ContactdetailsModel } from "../models/ContactDetailsModel";

export const Contactdetails: ContactdetailsModel[] = [
  {
    country: "United States of America",
    email: "info.us@softvire.com",
    phone: "1 (302) 321 6141",
    address:
      "200 Continental Drive Suite 401 Newark Delaware 19713 United States of America",
    locationUrl:
      "https://www.google.com/maps/place/200+Continental+Dr+Suite+401,+Newark,+DE+19713,+USA/@39.6870089,-75.660542,17z/data=!3m1!4b1!4m5!3m4!1s0x89c7011a53c83061:0x94bd690dac5445fe!8m2!3d39.6870089!4d-75.6583533",
  },
  {
    country: "United Kingdom",
    email: "info.uk@softvire.com",
    phone: "+442 0457 92321",
    address: "Kemp House, 160 City Road, London, EC1V 2NX, United Kingdom",
    locationUrl:
      "https://www.google.com/maps/place/Citigroup+Centre/@-33.8726651,151.2054522,17z/data=!3m2!4b1!5s0x6b12ae3e666166e3:0x6af5fe6b4faecf25!4m5!3m4!1s0x6b12af9b8c831cf1:0x494c0d65e361eeb8!8m2!3d-33.8726651!4d151.2076409",
  },
  {
    country: "Australia",
    email: "info.au@softvire.com",
    phone: "1300 121 111",
    address:
      "Citigroup Centre Level 13, 2-26 Park Street Sydney NSW 2000 Australia",
    locationUrl:
      "https://www.google.com/maps/place/200+Continental+Dr+Suite+401,+Newark,+DE+19713,+USA/@39.6870089,-75.660542,17z/data=!3m1!4b1!4m5!3m4!1s0x89c7011a53c83061:0x94bd690dac5445fe!8m2!3d39.6870089!4d-75.6583533",
  },
  {
    country: "New Zealand",
    email: "info.nz@softvire.com",
    phone: "09 988 60002",
    address: "6 Clayton Street, Newmarket Auckland 1023 New Zealand",
    locationUrl:
      "https://www.google.com/maps/place/Citigroup+Centre/@-33.8726651,151.2054522,17z/data=!3m2!4b1!5s0x6b12ae3e666166e3:0x6af5fe6b4faecf25!4m5!3m4!1s0x6b12af9b8c831cf1:0x494c0d65e361eeb8!8m2!3d-33.8726651!4d151.2076409",
  },
  {
    country: "Canada",
    email: "info.ca@softvire.com",
    phone: "437 886 2153",
    address: "3080 Yonge St. Suite 6060 Toronto Canada, Ontario M4N 3N1",
    locationUrl:
      "https://www.google.com/maps/place/Citigroup+Centre/@-33.8726651,151.205066,17z/data=!3m2!4b1!5s0x6b12ae3e666166e3:0x6af5fe6b4faecf25!4m6!3m5!1s0x6b12af9b8c831cf1:0x494c0d65e361eeb8!8m2!3d-33.8726651!4d151.2076409!16s%2Fg%2F11hyjkk983?entry=ttu",
  },
  {
    country: "Singapore",
    email: "info.sg@softvire.com",
    phone: "+6531745030",
    address: "2 Venture Drive, Level #24-01 Singapore 608526",
    locationUrl:
      "https://www.google.com/maps/place/Citigroup+Centre/@-33.8726651,151.2054522,17z/data=!3m2!4b1!5s0x6b12ae3e666166e3:0x6af5fe6b4faecf25!4m5!3m4!1s0x6b12af9b8c831cf1:0x494c0d65e361eeb8!8m2!3d-33.8726651!4d151.2076409",
  },
];
