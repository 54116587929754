import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../../common/AppColors";

interface Props {
  navOptions: any[];
  show: boolean;
  setShow(value: boolean): void;
}

export const NavigationDrawer: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  function redirect(path: string) {
    navigate(path);
    props.setShow(false);
  }

  return (
    <Drawer
      open={props.show}
      variant={"temporary"}
      anchor="left"
      onClose={() => props.setShow(false)}
      sx={{
        "& .MuiPaper-root": {
          bgcolor: AppColors.Blue,
          color: AppColors.Blue,
          p: 2
        },
      }}
    >
      <List>
        {props.navOptions.map((nav, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton
              onClick={() => redirect(nav.path)}
              sx={{ bgcolor: AppColors.LightGray, m: 1, borderRadius: 1.5 }}
            >
              <ListItemIcon>
                <nav.icon />
              </ListItemIcon>
              <ListItemText primary={nav.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
