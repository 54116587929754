import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Helmet } from "react-helmet-async";
import { scrollToTop, snakeToCamel } from "../common/Helper";
import {
  useGetAllSavedPlacesQuery,
  useGetAllVariationsQuery,
  useGetProductByIdQuery,
} from "../services/ProductServices";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ProductModel } from "../models/ProductModel";
import { StyleContext } from "../context/StyleContextProvider";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Icon,
  IconButton,
  Rating,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Wallpaper } from "../components/shared/Wallpaper";
import { ProductImages } from "../components/Product/ProductImages";
import {
  FavoriteBorder,
  FavoriteOutlined,
  RemoveShoppingCart,
  ShoppingBag,
  StarOutline,
} from "@mui/icons-material";
import { QuantityButton } from "../components/shared/QuantityButton";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../reduxenv/Slices/ProfileSlice";
import { ProductVariationModel } from "../models/ProductVariationModel";
import { CartModel } from "../models/CartModel";
import { AppColors } from "../common/AppColors";
import { BackToStockNotification } from "../components/shared/BackToStockNotification";
import {
  useAddToCartMutation,
  useDeleteCartItemMutation,
} from "../services/CartServices";
import { setShowCart } from "../reduxenv/Slices/CartSlice";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";
import {
  useAddToWishlistMutation,
  useDeleteFromWishlistMutation,
} from "../services/WishlistServices";
import { SavedPlacesModel } from "../models/SavedPlacesModel";
import useErrorPage from "../hooks/useErrorPage";

const ProductPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 786px)");
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("productPage")
  );
  const location = useLocation();
  const { id } = useParams();
  const [params] = useSearchParams();
  const productId = Number(id);
  const { data: productResponse, isFetching: isFetchingProduct } =
    useGetProductByIdQuery(
      { productId: productId! },
      { skip: !!location.state }
    );
  const dispatch = useDispatch();
  const [product, setProduct] = useState<ProductModel>(location.state);
  const hasVariation = product?.type === "variable";
  var profile = useSelector(getProfile);
  const [quantity, setQuantity] = useState(1);
  const [selectedVariation, setSelectedVariation] =
    useState<ProductVariationModel>();
  const [attributeElements, setAttributeElements] =
    useState<React.ReactNode[]>();
  const [variations, setVariations] = useState<ProductVariationModel[]>([]);
  const [selectedAttributesMap, setSelectedAttributesMap] =
    useState<Map<number, string>>();
  const [options, setOptions] = useState<string[]>([]);
  const [savedPlaces, setSavedPlaces] = useState<SavedPlacesModel>();
  const [isInvalidVariation, setIsInvalidVariation] = useState(false);
  const [toErrorPage] = useErrorPage();
  const isRenewal = params.get("isRenewal")
    ? Number(params.get("isRenewal")) || 0
    : undefined;

  const { data: allSavedPlacesResponse, refetch: refetchAllSavedPlaces } =
    useGetAllSavedPlacesQuery(
      { parentId: product?.parentId, productId: productId },
      {
        skip: !product,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: variationsResponse, isFetching: isFetchingVariations } =
    useGetAllVariationsQuery(
      { productId: product?.id },
      { skip: !product || !hasVariation }
    );
  const [addProductToCart] = useAddToCartMutation();
  const [deleteFromCart] = useDeleteCartItemMutation();
  const [addToWishlist] = useAddToWishlistMutation();
  const [deleteFromWishlist] = useDeleteFromWishlistMutation();

  var tempProductId = productId;
  var isDigitalGood = product?.isDigitalGood;
  var isInStock = product?.isInStock;
  var price = product?.price;
  var sku = product?.sku;
  var imageUrls: string[] = product?.images
    ? product?.images.map((x) => x.src)
    : [];
  var retailPrice = product?.retailPrice;
  var availability = product?.availability;
  if (selectedVariation) {
    tempProductId = selectedVariation.id;
    isDigitalGood = selectedVariation.isDigitalGood;
    isInStock = selectedVariation.isInStock;
    price = selectedVariation.price;
    sku = selectedVariation.sku;
    if (selectedVariation.image) {
      imageUrls = [selectedVariation.image.src];
    }
    retailPrice = selectedVariation.retailPrice;
    availability = selectedVariation.availability;
  }

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("productPage"));
    scrollToTop();
  }, [isMobile]);

  useEffect(() => {
    if (productResponse && productResponse.status === "success") {
      let tempProduct = snakeToCamel(productResponse.response) as ProductModel;
      if (tempProduct) {
        setProduct(tempProduct);
      } else {
        toErrorPage({ message: "Product Not Found!" });
      }
    }
  }, [productResponse]);

  useEffect(() => {
    if (variationsResponse && variationsResponse.status === "success") {
      let tempVariations = snakeToCamel(
        variationsResponse.response
      ) as ProductVariationModel[];
      setVariations(tempVariations);
    }
  }, [variationsResponse]);

  useEffect(() => {
    if (
      allSavedPlacesResponse &&
      allSavedPlacesResponse.status === "success" &&
      productId
    ) {
      let allSavedPlaces = snakeToCamel(
        allSavedPlacesResponse.response
      ) as SavedPlacesModel[];
      if (allSavedPlaces.length > 0) {
        let tempSavedPlace =
          allSavedPlaces.length === 1
            ? allSavedPlaces[0]
            : allSavedPlaces.find((x) => x.productId === productId);
        tempSavedPlace && setSavedPlaces(tempSavedPlace);
      }
    }
  }, [allSavedPlacesResponse, productId]);

  useEffect(() => {
    setInitialAttributesMap();
  }, [variations]);

  useEffect(() => {
    if (selectedAttributesMap) {
      let tempSelectedVariation = variations.find((variation) =>
        variation.attributes.every((attribute) =>
          Array.from(selectedAttributesMap.values()).includes(attribute.option)
        )
      );
      if (tempSelectedVariation) {
        let tempOptions = tempSelectedVariation.attributes.map(
          (attribute) => attribute.option
        );
        setOptions(tempOptions);
        setQuantity(1);
        setSelectedVariation(tempSelectedVariation);
      }
      setIsInvalidVariation(!tempSelectedVariation);
      generateAttributeElements();
    }
  }, [selectedAttributesMap]);

  useEffect(() => {
    dispatch(setIsLoading(isFetchingProduct || isFetchingVariations));
  }, [isFetchingProduct, isFetchingVariations]);

  function setInitialAttributesMap() {
    let tempSelectedAttributesMap = new Map<number, string>();
    if (
      hasVariation &&
      variations.length > 0 &&
      !selectedAttributesMap &&
      variations[0].attributes
    ) {
      variations[0].attributes.forEach((attribute) => {
        tempSelectedAttributesMap.set(attribute.id, attribute.option);
      });
      setSelectedAttributesMap(tempSelectedAttributesMap);
    }
  }

  function generateAttributeElements() {
    let tempAttributeElements = [];
    setAttributeElements(undefined);
    let tempAllAttributes = [];

    for (let variation of variations) {
      for (let attribute of variation.attributes) {
        let existingAttribute = tempAllAttributes.find(
          (element) => element.name === attribute.name
        );
        if (!existingAttribute) {
          tempAllAttributes.push({
            id: attribute.id,
            name: attribute.name,
            options: [attribute.option],
          });
        } else {
          if (existingAttribute.options.includes(attribute.option)) {
            continue;
          }
          existingAttribute.options.push(attribute.option);
        }
      }
    }

    for (let i = 0; i < tempAllAttributes.length; i++) {
      let optionElements: React.ReactNode[] = [];
      let currentAttribute = tempAllAttributes[i];
      currentAttribute.options.forEach((option, index) => {
        if (
          variations.some((variant) =>
            variant.attributes.map((attr) => attr.option).includes(option)
          )
        ) {
          if (selectedAttributesMap?.get(currentAttribute.id) === option) {
            optionElements.push(
              <Button
                key={i + "-" + index}
                sx={styles.variant}
                variant="contained"
              >
                {option}
              </Button>
            );
          } else {
            optionElements.push(
              <Button
                variant="outlined"
                key={i + "-" + index}
                sx={styles.variant}
                onClick={() => {
                  handleClickAttribute(currentAttribute.id, option);
                }}
              >
                {option}
              </Button>
            );
          }
        }
      });
      let attributeElement = (
        <React.Fragment key={i}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            Select {currentAttribute.name}
          </Typography>
          {optionElements}
          <Divider sx={{ mt: 1 }} />
        </React.Fragment>
      );
      tempAttributeElements.push(attributeElement);
    }
    setAttributeElements(tempAttributeElements);
  }

  function handleClickAttribute(attributeId: number, option: string) {
    let tempSelectedAttributesMap = new Map(selectedAttributesMap);
    tempSelectedAttributesMap.set(attributeId, option);
    setSelectedAttributesMap(tempSelectedAttributesMap);
  }

  function getCartModel() {
    return {
      image: imageUrls[0],
      productId: tempProductId,
      parentId: product.woocommerceId,
      name: product?.name,
      price: price,
      quantity: quantity,
      attributes: options.join(","),
      isDigitalGood: isDigitalGood,
      isSimpleProduct: !hasVariation,
      isInStock: isInStock,
      ...(isRenewal && { isRenewal: isRenewal }),
    } as CartModel;
  }

  function handleClickBuyNow() {
    let checkoutProduct = getCartModel();
    navigate("/checkout", { state: [checkoutProduct] });
  }

  function handleClickAddToCart() {
    let checkoutProduct = getCartModel();
    dispatch(setIsLoading(true));
    if (!savedPlaces?.inCart) {
      addProductToCart(checkoutProduct)
        .then(() => {
          dispatch(setShowCart(true));
          dispatch(setIsLoading(false));
          refetchAllSavedPlaces();
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    } else {
      deleteFromCart(productId)
        .then(() => {
          refetchAllSavedPlaces();
          dispatch(setIsLoading(false));
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }

  function addDeleteFromWishlist() {
    if (productId) {
      dispatch(setIsLoading(true));
      if (!savedPlaces?.inWishlist) {
        addToWishlist({
          productId: productId,
          parentId: product.woocommerceId,
        })
          .then(() => {
            refetchAllSavedPlaces();
            dispatch(setIsLoading(false));
          })
          .catch(() => {
            dispatch(setIsLoading(false));
          });
      } else {
        deleteFromWishlist({ parentId: product.parentId, productId: productId })
          .then(() => {
            refetchAllSavedPlaces();
            dispatch(setIsLoading(false));
          })
          .catch(() => {
            dispatch(setIsLoading(false));
          });
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{product?.name}</title>
      </Helmet>
      <Header />
      <Grid container justifyContent={"center"}>
        <Wallpaper
          content={
            <Box sx={styles.wallpaperContent}>
              <Grid container alignItems={"center"} justifyContent={"unset"}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h1" sx={styles.productName}>
                    {product?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography>SKU: {sku}</Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Rating
                    value={
                      product?.averageRating ? Number(product.averageRating) : 0
                    }
                    emptyIcon={
                      <StarOutline
                        sx={styles.noRating}
                        fontSize={isMobile ? "small" : "medium"}
                      />
                    }
                    readOnly
                    size={isMobile ? "small" : "medium"}
                  />
                </Grid>
                <Grid item xs={2} md={2.5}>
                  {isMobile ? (
                    <IconButton
                      onClick={addDeleteFromWishlist}
                      size="small"
                      sx={styles.wishlistButtonMobile}
                    >
                      {savedPlaces?.inWishlist ? (
                        <FavoriteOutlined />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton>
                  ) : (
                    <Button
                      onClick={addDeleteFromWishlist}
                      sx={styles.wishlistButton}
                      variant="outlined"
                      endIcon={
                        savedPlaces?.inWishlist ? (
                          <FavoriteOutlined />
                        ) : (
                          <FavoriteBorder />
                        )
                      }
                      disabled={isInvalidVariation}
                    >
                      {savedPlaces?.inWishlist
                        ? "Remove"
                        : "Add to My Favourites"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          }
        />
        <Container maxWidth={"xl"}>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} lg={6} order={{ lg: 2, xs: 3 }}>
              <Box sx={styles.leftWrapper}>
                <Card sx={styles.actionWrapper}>
                  <CardContent>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={12} md={6}>
                        {attributeElements && attributeElements.length > 0 && (
                          <Box sx={styles.variationsBox}>
                            {attributeElements}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            borderRadius: 1.8,
                            border: "1px solid " + AppColors.MainColor,
                            p: 1,
                            pt: 0,
                          }}
                        >
                          {!isInStock ? (
                            <BackToStockNotification productId={productId} />
                          ) : (
                            <QuantityButton
                              quantity={quantity}
                              setQuantity={setQuantity}
                              max={availability}
                            />
                          )}
                        </Box>
                        {retailPrice > 0 && (
                          <Box sx={styles.rrpWrapper}>
                            <Typography variant="subtitle1">
                              Recommended Retail Price
                            </Typography>
                            {price ? (
                              <Typography variant="h5" fontWeight={"bold"}>
                                {retailPrice?.toFixed(2)}{" "}
                                {profile.country.currency}
                                <span style={styles.rrp}>
                                  {`( ${(price - (retailPrice ?? 0)).toFixed(
                                    2
                                  )} ${profile.country.currency} )`}
                                </span>
                              </Typography>
                            ) : (
                              <Skeleton
                                variant="text"
                                width={"40%"}
                                height={18}
                              />
                            )}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={styles.priceWrapper}>
                          <Typography variant="h4" sx={styles.price}>
                            {price ? (
                              <>
                                {(price * quantity).toFixed(2)}{" "}
                                {profile.country.currency}
                              </>
                            ) : (
                              <Skeleton variant="text" />
                            )}
                            <p style={styles.gst}>
                              {profile.country.isTaxIncluded
                                ? "Including GST "
                                : "Excluding GST "}
                              {profile.country.taxPercentage}%
                            </p>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={!isInStock || isInvalidVariation}
                          sx={styles.mainButton}
                          onClick={handleClickBuyNow}
                        >
                          Buy Now
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!isInStock || isInvalidVariation}
                          sx={styles.mainButton}
                          endIcon={
                            <Icon>
                              {savedPlaces?.inCart ? (
                                <RemoveShoppingCart />
                              ) : (
                                <ShoppingBag />
                              )}
                            </Icon>
                          }
                          onClick={handleClickAddToCart}
                        >
                          {savedPlaces?.inCart ? "Remove" : "Add to cart"}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card sx={{ mb: 4 }} variant="outlined">
                  <CardContent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.shortDescription,
                      }}
                    ></div>
                    <Divider />
                    <div
                      dangerouslySetInnerHTML={{ __html: product?.description }}
                    ></div>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={5} order={{ lg: 3, xs: 2 }}>
              {imageUrls.length > 0 && (
                <Box sx={styles.rightWrapper}>
                  <Card variant="outlined">
                    <CardContent>
                      <ProductImages imageUrls={imageUrls} />
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};

export default ProductPage;
