import { SearchRounded } from "@mui/icons-material";
import { Box, TextField, InputAdornment, IconButton, SxProps } from "@mui/material";
import React, { useState } from "react";
import { AppColors } from "../../common/AppColors";
import { useNavigate } from "react-router-dom";
import { showToastMessage } from "../../common/Helper";

interface Props {
  searchQuery?: string;
  styles?: SxProps;
}

export const SearchBar: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>(
    props.searchQuery ?? ""
  );
  function handleSearchProducts(event: React.FormEvent) {
    event.preventDefault();
    if (searchQuery && searchQuery.trim() !== "") {
      navigate("/search/" + searchQuery);
    } else {
      showToastMessage("error", "Keyword can not be empty!");
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      ml={"auto"}
      mr={0}
      color={AppColors.LightGray}
      component={"form"}
      onSubmit={handleSearchProducts}
      width={{xs: "100%", md: "unset"}}
    >
      <TextField
        placeholder="Search in Softvire.."
        variant="standard"
        size="small"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        sx={{
          bgcolor: AppColors.White,
          borderRadius: 2,
          pr: 1.5,
          pl: 1.5,
          pt: 0.8,
          pb: 0.8,
          ...props.styles
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton type="submit">
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true
        }}
      />
    </Box>
  );
};
