import React from "react";
import { OtherResellerModel } from "../../models/OtherResellerModel";
import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../shared/ControlledTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { OtherResellersSchema } from "../../schemas/OtherResellersSchema";
import { usePostOtherResellerMutation } from "../../services/ProfileServices";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import { showToastMessage } from "../../common/Helper";

interface Props {
  handleAddReseller(): void;
}

export const AddOtherResellerForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [registerReseller] = usePostOtherResellerMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OtherResellerModel>({
    resolver: yupResolver(OtherResellersSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  async function submit(otherResellerModel: OtherResellerModel) {
    dispatch(setIsLoading(true));
    registerReseller(otherResellerModel)
      .then((response: any) => {
        let responseModel = response.data as ResponseModel;
        if (responseModel.status === "success") {
        }
        showToastMessage(responseModel.status, responseModel.message);
        props.handleAddReseller();
        dispatch(setIsLoading(false));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  }

  return (
    <DialogContent>
      <DialogTitle>Add Reseller</DialogTitle>
      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={handleSubmit(submit)}
      >
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="firstName"
            label="First Name*"
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="lastName"
            label="Last Name*"
            error={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="email"
            label="Email Address*"
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="phone"
            label="Phone*"
            error={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant={"contained"} fullWidth>
            Add
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
